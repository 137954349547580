import { ISaveFileBody } from "../applications/types";
import axios from "axios";

export const paths = {
    baseURL: 'https://api.neva-online.ru',
    socketPath: 'https://api.neva-online.ru',
    baseURLOpen: 'api/data/open',
    baseURLClose: 'api/data',
    baseURLClosePult : 'api/pult',
    baseURLCloseFiles: 'api/file',
    baseURLCloseReport: 'api/v1',

    baseURLCloseTasks: 'api/tikcets',

    refreshToken: 'RefreshToken',

    //Общее
    getWorkersWithGroups: '/sel_users',

    //количество заявок разного типа
    getApplicationsCounts: '/sel_application_counts',

    //Авторизация
    authorization: 'Account/LoginActiveDirectoryticket',

    //Заявки
    getApplicationList: 'sel_tasks',
    getApplicationInfo: 'sel_task',
    iudApplication: 'iud_task',
    getAppStatuses: 'sel_task_statuses',
    getTaskHistory: 'sel_task_history',
    getTaskActions: 'sel_events_ticket',

    //Отчеты
    getReports: '/get-ticket-report ',
    showReport: '/ShowReportBase64',

    //Новая заявка
    getTaskSamples: '/sel_task_samples',
    getPeriods: '/sel_task_periodicity',
    getSystems: '/sel_systems',
    iudSystem: '/iud_system',
    getDevices: '/sel_devices',
    getWorkersTree: '/sel_workers_with_groups',
    getClientInfo: '/sel_clients',
    getTags: '/sel_tags',
    saveFile: 'SaveFileByte',
    selPult : '/sel_pults',


    //Уведомления
    socketConnection: '/Notification',
    getMyNotifications: '/sel_notifications',
    checkedNotifications: '/check_new_notifications',

    //Сотрудники
    getWorkersListOrItem: 'sel_users',
    iudGroup: 'iud_contractor_group',
    getGroups: 'sel_contractor_groups',
    addWorkersToGroup: 'appoint_contractor_group',
    iudWorker: 'iud_user',


    //Справочники
    getDirectoryGroups: 'sel_directory_groups',
    getDirectoryCategories: 'sel_task_categories',
    getDirectoryRoles: 'sel_contractor_roles',
    getPriority: 'sel_categories_priority',
    iudCategory: 'iud_task_categories',
    iudRole: 'iud_contractor_role',
    getTaskComments: 'sel_task_comments',
    sendComment: 'send_task_comment',
    getMarks: 'sel_tags',
    iudDevice: 'iud_device',
    iudMark: 'iud_tag',
    getColors: 'sel_colors',
    getCelebrationDays: 'sel_workdays',
    iudCelebrationDay: 'iud_workday',
    getTypes: 'sel_types',
    calculateTaskDate: 'calculate_task_date',

    //Информация о пользователе
    getMyInfo: 'sel_user',

    //формвью
    getFormview: 'sel_formviews',

    //Полный список ролей1
    getFullRoleList: 'sel_roles',

    //Удаленные
    refreshTask : 'restore_task'
}