// @ts-nocheck
import React, { createRef, useEffect, useRef, useState } from 'react';
import { HeadTitle } from '../common/HeadTitle/PageTitle';
import { DividingLine } from "../common/DividingLine/DividingLine";
import { DatePickerComponent } from "./DatePicker/DatePickerComponent";
import { key } from "../../store/api";
import { observer } from "mobx-react-lite";
import { Fade, IconButton, ListItem } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { SimpleSelect } from "../helpers/Selects/SimpleSelect";
import { ApplicationsStore } from "../../store/applications/Applications";
import { Fallback } from "../helpers/Fallback/Fallback";
import { SimpleTreeSelect } from "../helpers/Selects/SimpleTreeSelect";
import { openNotification } from "../helpers/notification";
import WorkersPageStore from "../../store/WorkersPage/WorkersStore";
import { BinIcon, DownloadBtn } from "../../icons/icons";
import { connect } from "react-redux";
import { TRootState } from "../../redux/store";
import moment, { Moment } from "moment";
import useDebouncedFunction from "../helpers/useDebounceFunction";
import { MultipleSelect } from "../helpers/Selects/MultipleSelect";
import { SimpleInput } from "../helpers/Inputs/inputs";
import { formatIsoToDate, formatPhoneByPattern } from '../helpers/utils';
import { Test } from '../../test/Test';
import clsx from 'clsx';
import { IProgressFileItem, useFilesLoaderState } from '../helpers/FileInput/utils';


const s = require('./style.module.scss');

interface INewApplicationComponentProps {
    flag: 'addApp' | 'editApp' | 'addTemplate' | 'editTemplate'

    applicationReduxId: string | number

    [key: string]: any
}

const NewApplication: React.FC<INewApplicationComponentProps> = observer(({
    flag,
    applicationReduxId
}) => {

    const router = useHistory()

    const isMobile: boolean = window.outerWidth <= 425

    /*data and requests from Mobx*/

    const {
        requestApplicationInfo,
        requestTaskSamples,
        applicationsInfoLoading,
        requestPeriods,
        requestSystems,
        requestIudApplication,
        requestDevices,
        requestWorkersTree,
        requestClient,
        requestTags,
        periods,
        taskSamples,
        periodsLoading,
        taskSamplesLoading,
        systems,
        systemsLoading,
        devices,
        devicesLoading,
        workersTree,
        workersTreeLoading,
        clientInfo,
        clientInfoLoading,
        tags,
        saveFile,
        applicationId,
        applicationInfo,
        requestApplications,
        requestCategories,
        categoriesLoading,
        categories,
        devicesList,
        calculatedTaskDate,
        calculatedTaskDateLoading,
        requestCalculateTaskDate,
        requestSelPults,
        pults,
        setDevices
    } = ApplicationsStore


    const {
        workersWithGroups,
        requestWorkersWithGroups,
        admins,
        adminsLoading,
        executors,
        executorsLoading,
        observers,
        observersLoading,
        requestExecutors,
        requestObservers
    } = WorkersPageStore

    /*Для iudTask  устройства*/

    const [systemId, setSystemId] = useState<string | number>('')

    /*Для iudTask основная информация*/
    const [taskName, setTaskName] = useState<string>('')
    const [taskDescription, setTaskDescription] = useState<string>('')
    const [taskDescriptionExtra, setTaskDescriptionExtra] = useState<string>('')
    const [taskSampleId, setTaskSampleId] = useState<string | number>('')
    const [adminId, setAdminId] = useState<string | number>('')
    const [adminName, setAdminName] = useState<string>('')
    //Относится сюда же, нужно для понимания кастомная ли система, от этого меняется отображение выбора устройства на вебе, для этого же два поля внизу
    const [isCustom, setIsCustom] = useState<boolean | undefined>(undefined)
    const [deviceName, setDeviceName] = useState<string | undefined>(undefined)
    const [deviceNumber, setDeviceNumber] = useState<number | undefined>(undefined)
    const [panel, setPanel] = useState<string | undefined>('')

    const [numberOfPult, setNumberOfPult] = useState<string | number | undefined>(undefined)
    const [addressOfPult, setAdressOfPult] = useState<string | undefined>(undefined)

    /*Для iudTask информация о клиенте*/
    const [taskOrganizationName, setTaskOrganizationName] = useState<string>('')
    const [taskContactName, setTaskContactName] = useState<string>('')
    const [taskContactPhone, setTaskContactPhone] = useState<string>('')
    const [taskContactAddress, setTaskContactAddress] = useState<string>('')
    const [taskSystemAddress, setTaskSystemAddress] = useState<string>('')
    const [taskContactDeviceId, setTaskContactDeviceId] = useState<string | number>('')


    /*Для iudTask исполнитель*/

    const [workersTreeState, setWorkersTreeState] = useState<(number | string)[]>([])

    /*Для iudTask категория*/

    const [categoryState, setCategoryState] = useState<string | number>('')

    /*Для iudTask наблюдатель*/

    const [observersTreeState, setObserversTreeState] = useState<(number | string)[]>([])

    /*Для iudTask срок*/

    const [date, setDate] = useState<Date | Moment | undefined>(undefined)

    /*Для iudTask повторная заявка*/

    const [secondaryApplication, setSecondaryApplication] = useState<string | number>('')

    /*Для iudTask Тэги*/

    const [extraInfo, setExtraInfo] = useState<string>('')

    const [stateTags, setStateTags] = useState<(string | number)[]>([])

    /*Для загрузки фотографий*/


    const { addFiles: addImages, state: images, setState: setImagesLocal, loaded: load } = useFilesLoaderState();
    useEffect(() => {
        if (load) if (images.length && images.find(image => !image.file.base64) === undefined) {
            setFiles(images.map((image: IProgressFileItem) => ({
                id: image.id,
                url: image.file.base64,
                type: 'img',
                name: image.jsFile?.name,
                ext: image.file.extension,
            })))
        }
        console.log(images)
    }, [images, load])

    const [files, setFiles] = useState<any>([])
    const [debLoading, setDebLoading] = useState<boolean>(false)

    /*Для отключения кнопки сохранения*/
    const [disabledButton, setDisabledButton] = useState<boolean>(false)

    /*useEffects*/

    /*при изменении системы, идет запрос за перечнем устройств этой системы*/

    /*изменив пультовый номер / пультовый номер, подставляется информация о клиенте, если таковая имеется */
    useEffect(() => {
        taskContactDeviceId && taskContactDeviceId !== '' && requestClient({
            idSystem: +systemId, idDevice: +taskContactDeviceId
        })
        devicesList && devicesList?.length > 0 && setTaskSystemAddress(devicesList.find(i => i.id === taskContactDeviceId)?.address || '')
    }, [taskContactDeviceId])


    /*useEffect на изменение устройства*/
    useEffect(() => {
        if (clientInfo) {
            setTaskOrganizationName(clientInfo?.organization)
            setTaskContactName(clientInfo?.name)
            clientInfo?.phone?.value && setTaskContactPhone(formatPhoneByPattern(clientInfo?.phone?.value))
            setTaskContactAddress(clientInfo?.address)
        }
    }, [clientInfo])

    useEffect(() => {
        if (applicationInfo && ((flag === 'editApp') || (flag === 'editTemplate'))) {

            applicationInfo?.name && setTaskName(applicationInfo?.name)

            applicationInfo?.description && setTaskDescription(applicationInfo?.description)

            if (applicationInfo && applicationInfo.system && applicationInfo.system.id) {
                setSystemId(applicationInfo.system.id);
            }
            applicationInfo?.client?.name && setTaskContactName(applicationInfo?.client?.name)

            applicationInfo?.client?.phone?.value && setTaskContactPhone(formatPhoneByPattern(applicationInfo?.client?.phone?.value))

            applicationInfo?.client?.address && setTaskContactAddress(applicationInfo?.client?.address)
            applicationInfo?.client?.organization && setTaskOrganizationName(applicationInfo?.client?.organization)


            applicationInfo?.device?.id && setTaskContactDeviceId(applicationInfo?.device?.id)
            applicationInfo?.device?.name && setDeviceName(applicationInfo?.device?.name)
            applicationInfo?.device?.number && setDeviceName(applicationInfo?.device?.number)


            applicationInfo?.date?.value && setDate(moment(applicationInfo?.date?.value))

            applicationInfo?.responsible && applicationInfo?.responsible?.length > 0 &&

                setWorkersTreeState(applicationInfo?.responsible?.map(i => {
                    return i.id
                }))
            applicationInfo?.observers && applicationInfo?.observers?.length > 0 &&
                setObserversTreeState(applicationInfo?.observers?.map(i => {
                    return i.id
                }))

            applicationInfo?.tags && applicationInfo?.tags?.length > 0 && setStateTags(applicationInfo?.tags?.map(i => {
                return i.id
            }))
            applicationInfo?.category?.id && setCategoryState(applicationInfo?.category?.id)
            applicationInfo?.admin?.id && setAdminId(applicationInfo?.admin?.id)
            applicationInfo?.admin?.name && setAdminName(applicationInfo?.admin?.name)

            applicationInfo?.periodicity?.id && setSecondaryApplication(applicationInfo?.periodicity?.id)

            applicationInfo?.device?.panel && setPanel(applicationInfo?.device?.panel)
            
            applicationInfo?.device?.address && setAdressOfPult(applicationInfo?.device?.address)

            applicationInfo?.device?.name && setTaskSystemAddress(applicationInfo?.device?.name)
            applicationInfo?.device?.number &&  setNumberOfPult(applicationInfo?.device?.number)
        }
    }, [applicationInfo])



    /*useEffect на изменение шаблона, подстанавливаются данные во всю зяавку*/
    useEffect(() => {
        taskSampleId !== '' && requestApplicationInfo(+taskSampleId, value => {
            value?.name && setTaskName(value?.name)
            value?.description && setTaskDescription(value?.description)
            value?.system.id && setSystemId(value?.system?.id)
            value?.client?.name && setTaskContactName(value?.client?.name)
            value?.client?.phone?.value && setTaskContactPhone(formatPhoneByPattern(value?.client?.phone?.value))
            value?.client?.address && setTaskContactAddress(value?.client?.address)
            value?.device?.id && setTaskContactDeviceId(value?.device?.id)
            value?.date?.value && setDate(moment(value?.date?.value))
            value?.device?.name && setDeviceName(value?.device?.name)
            value?.device?.number && setDeviceName(value?.device?.number)
            value?.responsible && value?.responsible?.length > 0 &&
                setWorkersTreeState(value?.responsible?.map(i => {
                    return i.id
                }))
            value?.observers && value?.observers?.length > 0 &&
                setObserversTreeState(value?.observers?.map(i => {
                    return i.id
                }))
                debugger
            value?.tags && value?.tags?.length > 0 && setStateTags(value?.tags?.map(i => {
                return i.id
            }))
            value?.category?.id && setCategoryState(value?.category?.id)
        })
    }, [taskSampleId])



    useEffect(() => {
        // if (!localStorage.getItem(key.main)) window.location.href = '/authorization'


        requestWorkersWithGroups({
            flag: 'withGroups',
        })

        requestWorkersWithGroups({
            flag: 'admins',
        })

        requestObservers({
            flag: 'performers',
        })

        requestExecutors({
            flag: 'performers'
        })

        requestApplications({
            flag: 'samples',
            offset: 0,
            count: 120
        })
        requestPeriods()
        requestSystems()
        requestTags()
        requestCategories()
        requestSelPults()
        requestWorkersWithGroups({
            flag: 'withGroups'
        })
        switch (flag) {
            case "editApp": {
                requestApplicationInfo(+applicationReduxId)
                return;
            }
            case "editTemplate": {
                requestApplicationInfo(+applicationReduxId)
                return;
            }
            case "addTemplate": {
                setTaskName('')
                setTaskDescription('')
                setDeviceNumber(undefined)
                setDeviceName(undefined)
                setSystemId('')
                setTaskContactPhone('')
                setTaskContactAddress('')
                setTaskSystemAddress('')
                setTaskContactDeviceId('')
                setTaskContactName('')
                setWorkersTreeState([])
                setObserversTreeState([])
                return;
            }
            case "addApp": {

                setTaskName('')
                setTaskDescription('')
                setSystemId('')
                setDeviceNumber(undefined)
                setDeviceName(undefined)
                setTaskContactPhone('')
                setTaskContactAddress('')
                setTaskSystemAddress('')
                setTaskContactDeviceId('')
                setTaskContactName('')
                setWorkersTreeState([])
                setObserversTreeState([])
                return;
            }
            default:
                return

        }


    }, [])

    /*useEffect на изменение категории*/

    useEffect(() => {
        categoryState !== '' && requestCalculateTaskDate({
            categoryId: +categoryState
        })
    }, [categoryState])

    useEffect(() => {
        if (calculatedTaskDate) {
            let a = formatIsoToDate(calculatedTaskDate)
            setDate(new Date(calculatedTaskDate))
        }
    }, [calculatedTaskDate])
    /*functions*/



    const debouncedExecutorsSearch = useDebouncedFunction((value: string) => {
        requestExecutors({
            searchValue: value,
            flag: 'performers'
        })
    }, 1000)


    const debouncedObserversSearch = useDebouncedFunction((value: string) => {
        requestObservers({
            searchValue: value,
            flag: 'performers'
        })
    }, 1000)

    const debouncedAdminsSearch = useDebouncedFunction((value: string) => {
        requestWorkersWithGroups({
            flag: 'admins',
            searchValue: value
        })
    }, 1000)

    const deviceHandler = useDebouncedFunction((value?: string) => {
        value !== '' && requestDevices(panel, value)
    }, 1000)


    //Сохранение фотографий
    //string to array buffer
    const s2ab = (s: string) => {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }



    //Запросы
    const requestHandler = () => {
        switch (flag) {
            case "editTemplate": {
                requestIudApplication({
                    flag: 'U',
                    client: {
                        id: clientInfo ? +clientInfo?.id : null,
                        name: taskContactName,
                        phone: {
                            value: taskContactPhone
                        },
                        address: taskSystemAddress,
                        organization: taskOrganizationName
                    },
                    date: { value: date && typeof date !== 'string' ? date.toISOString() : date },
                    device: isCustom ? { name: deviceName, number: deviceNumber } : { id: taskContactDeviceId ? +taskContactDeviceId : null },
                    tags: stateTags.length > 0 ? stateTags?.map(i => {
                        return {
                            id: +i
                        }
                    }) : [],
                    system: { id: +systemId },
                    responsible: workersTreeState?.map(i => {
                        return {
                            id: +i
                        }
                    }),
                    name: taskName,
                    description: taskDescription + taskSampleId,
                    id: applicationReduxId,
                    observers: observersTreeState?.map(i => {
                        return {
                            id: +i
                        }
                    }),
                    category: categoryState !== '' ? {
                        id: +categoryState
                    } : undefined,
                    admin: adminId !== '' ? {
                        id: adminId,
                        name: adminName
                    } : undefined,
                    periodicity: secondaryApplication ? {
                        id: +secondaryApplication
                    } : undefined
                }, () => {
                    setDisabledButton(true)
                    openNotification({
                        type: 'success',
                        message: 'Отредатировано'
                    })
                    router.push('/directory/templates')
                })
                return;
            }
            case "editApp": {
                requestIudApplication({
                    flag: 'I',
                    client: {
                        id: clientInfo ? +clientInfo?.id : null,
                        name: taskContactName,
                        phone: {
                            value: taskContactPhone
                        },
                        address: taskSystemAddress,
                        organization: taskOrganizationName
                    },
                    date: { value: date && typeof date !== 'string' ? date.toISOString() : date },
                    device: {
                        address: addressOfPult,
                        name: taskSystemAddress,
                        number: numberOfPult,
                        panel: panel
                    },
                    tags: stateTags.length > 0 ? stateTags?.map(i => {
                        return {
                            id: +i
                        }
                    }) : [],
                    system: { id: systemId !== '' ? +systemId : null },
                    responsible: workersTreeState?.map(i => {
                        return {
                            id: +i
                        }
                    }),
                    name: taskName,
                    description: taskDescription + taskSampleId,
                    id: undefined,
                    observers: observersTreeState?.map(i => {
                        return {
                            id: +i
                        }
                    }),
                    category: categoryState !== '' ? {
                        id: +categoryState
                    } : undefined,
                    admin: adminId !== '' ? {
                        id: adminId,
                        name: adminName
                    } : undefined,
                    periodicity: secondaryApplication ? {
                        id: +secondaryApplication
                    } : undefined
                }, () => {
                    setDisabledButton(true)

                    openNotification({
                        type: 'success',
                        message: 'Отредатировано'
                    })
                    router.push('/applications')
                })
                return;

            }
            case "addTemplate": {
                requestIudApplication({
                    flag: 'I',
                    is_sample: true,
                    client: {
                        id: clientInfo ? +clientInfo?.id : null,
                        name: taskContactName,
                        phone: {
                            value: taskContactPhone
                        },
                        address: taskSystemAddress,
                        organization: taskOrganizationName
                    },
                    date: { value: date && typeof date !== 'string' ? date.toISOString() : date },
                    device: isCustom ? { name: deviceName, number: deviceNumber } : { id: taskContactDeviceId ? +taskContactDeviceId : null },
                    tags: stateTags.length > 0 ? stateTags?.map(i => {
                        return {
                            id: +i
                        }
                    }) : [],
                    system: { id: systemId !== '' ? +systemId : null },
                    responsible: workersTreeState?.map(i => {
                        return {
                            id: +i
                        }
                    }),
                    name: taskName,
                    description: taskDescription + taskSampleId,
                    id: undefined,
                    observers: observersTreeState?.map(i => {
                        return {
                            id: +i
                        }
                    }),
                    category: categoryState !== '' ? {
                        id: +categoryState
                    } : undefined,
                    admin: adminId !== '' ? {
                        id: adminId,
                        name: adminName
                    } : undefined,
                    periodicity: secondaryApplication ? {
                        id: +secondaryApplication
                    } : undefined
                }, () => {
                    setDisabledButton(true)

                    openNotification({
                        type: 'success',
                        message: 'Шаблон успешно сформирован'
                    })
                    router.push('/directory/templates')
                })
                return;
            }
            case "addApp": {
                requestIudApplication({
                    flag: 'I',
                    client: {
                        id: clientInfo ? +clientInfo?.id : null,
                        name: taskContactName,
                        phone: {
                            value: taskContactPhone
                        },
                        address: taskSystemAddress,
                        organization: taskOrganizationName
                    },
                    date: { value: date && typeof date !== 'string' ? date.toISOString() : date },
                    device: {
                        address: addressOfPult,
                        name: taskSystemAddress,
                        number: numberOfPult,
                        panel: panel
                    },
                    tags: stateTags.length > 0 ? stateTags?.map(i => {
                        return {
                            id: +i
                        }
                    }) : [],
                    system: { id: systemId !== '' ? +systemId : null },
                    responsible: workersTreeState?.map(i => {
                        return {
                            id: +i
                        }
                    }),
                    name: taskName,
                    description: taskDescription + taskSampleId,
                    id: undefined,
                    observers: observersTreeState?.map(i => {
                        return {
                            id: +i
                        }
                    }),
                    category: categoryState !== '' ? {
                        id: +categoryState
                    } : undefined,
                    admin: adminId !== '' ? {
                        id: adminId,
                        name: adminName
                    } : undefined,
                    periodicity: secondaryApplication ? {
                        id: +secondaryApplication
                    } : undefined
                }, (id: number) => {
                    setDisabledButton(true)

                    openNotification({
                        type: 'success',
                        message: 'Заявка успешно сформирована, загружаем файлы...'
                    })
                    let reader = new FileReader()
                    saveFile({
                        "Id": `${id}`,
                        "Files": files?.map((file: any) => ({
                            "Name": file?.name,
                            "Extention": file?.extention,
                            "Base64": file?.url,
                            "Rewrite": true,
                            "DataType": 'CONTRACTOR'
                        }))
                    },
                        () => {
                            router.push('/applications')
                        }
                    )
                })
                return;
            }
            default:
                return

        }
    }

    //Удаление фото
    const deleteFileHandler = (id: string) => {
        setImagesLocal(images?.filter(image => image?.id !== id))
    }

    /*ref for photo load*/
    const hiddenInputRef: any = React.createRef()

    const handleClick = (event: any) => {
        event.preventDefault()
        hiddenInputRef.current.click()
    }




    const addImageHandler = (files: FileList) => {
        addImages(files, undefined)
    }


    // useEffect(() => {
    //     requestTaskSamples()
    // }, [])



    return (
        <Fade in timeout={500}>
            {workersTreeLoading || applicationsInfoLoading ?
                <Fallback /> :
                <div className={s.main_page_wrap}>
                    {/* <Header/>*/}
                    <HeadTitle title={
                        (flag === 'addApp')
                            ? 'Создать заявку'
                            : flag === 'editApp'
                                ? 'Редактировать заявку'
                                : flag === 'addTemplate'
                                    ? 'Создать шаблон'
                                    : 'Редактировать шаблон'
                    } />
                    <div className={s.basic_info_wrap}>
                        {((flag === 'editApp') || (flag === 'addApp')) && <SimpleSelect
                            value={taskSampleId}
                            className={s.select}
                            size={'xs'}
                            showSearch
                            items={taskSamples}
                            width={isMobile ? '100%' : 679}
                            onChange={(value, option) => {
                                setTaskSampleId(value)
                                setTaskDescriptionExtra(option)
                            }} placeholder={'Шаблон'} />}
                        <SimpleSelect
                            value={adminId}
                            className={s.select}
                            size={'xs'}
                            showSearch
                            onSearch={(value) => debouncedAdminsSearch(value)}
                            loading={adminsLoading}
                            items={admins?.length > 0 ? admins : adminId && adminName ? [{ id: adminId, name: adminName }] : []}
                            width={isMobile ? '100%' : 679}
                            onChange={(value, option) => {
                                setAdminId(option.value)
                                setAdminName(option.children)
                            }} placeholder={'Администратор'} />
                        <div className={s.title_basic_info}>Основная информация</div>
                        <input
                            onChange={(event) => setTaskName(event.currentTarget.value)}
                            value={taskName}
                            placeholder={'Наименование заявки'}
                            className={s.input} />
                        <textarea
                            value={taskDescription}
                            onChange={(e) => setTaskDescription(e.currentTarget.value)}
                            placeholder={'Описание'} className={s.description_basic_info} />
                        <SimpleSelect
                            value={categoryState}
                            className={s.select}
                            size={'xs'}
                            showSearch
                            loading={categoriesLoading}
                            items={categories}
                            width={isMobile ? '100%' : 679}
                            onChange={setCategoryState}
                            placeholder={'Категория'} />

                        <SimpleSelect
                            value={systemId}
                            className={s.select}
                            size={'xs'}
                            showSearch
                            items={pults}
                            width={isMobile ? '100%' : 679}

                            onChange={(value) => {
                                ApplicationsStore.setDevices()
                                setTaskContactDeviceId('')
                                setSystemId(value)
                                setPanel(pults.find(element => element.id === value)?.panel);
                                setIsCustom(systems.find(system => system?.id === value)?.isCustom)
                                setNumberOfPult('')
                                setAdressOfPult('')
                                setTaskSystemAddress('')
                                setTaskSystemAddress('')
                                debugger
                            }} placeholder={'Система'} />

                        <div className={s.title_basic_info}>Информация о клиенте</div>
                        {clientInfoLoading
                            ? <div>
                                <Fallback />
                            </div>
                            : <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {isCustom &&
                                    <input
                                        value={deviceNumber}
                                        onChange={(e) => {
                                            if (!isNaN(+e.currentTarget.value)) {
                                                setDeviceNumber(+e.currentTarget.value)
                                            }
                                        }}
                                        placeholder={'Пультовый номер'} className={s.input}
                                    />}

                                {isCustom && <input
                                    value={deviceName}
                                    onChange={(e) => setDeviceName(e.currentTarget.value)}
                                    placeholder={'Наименование объекта'} className={s.input} />
                                }

                                {!isCustom && <SimpleSelect className={s.select}
                                    value={addressOfPult}
                                    disabled={!systemId}
                                    size={'xs'}
                                    showSearch
                                    items={devices?.length > 0 ? devices : []}
                                    width={isMobile ? '100%' : 679}
                                    onSearch={(value) => deviceHandler(value)}
                                    loading={devicesLoading}
                                    onChange={(value, option) => {
                                        setNumberOfPult(devices.find(element => element?.name?.includes(option.children))?.number)
                                        setAdressOfPult(devices.find(element => element?.name?.includes(option.children))?.name)
                                        setTaskSystemAddress(devices.find(element => element?.name?.includes(option.children))?.address)
                                    }}
                                    placeholder={'Пультовый номер'}
                                />}

                                <input
                                    onChange={(event) => setTaskSystemAddress(event.currentTarget.value)}
                                    value={taskSystemAddress}
                                    placeholder={'Адрес объекта'}
                                    className={s.input} />

                            </div>}
                        <input
                            value={taskOrganizationName}
                            onChange={(e) => setTaskOrganizationName(e.currentTarget.value)}
                            placeholder={'Организация'}
                            className={s.input} />
                        <input
                            value={taskContactName}
                            onChange={(e) => setTaskContactName(e.currentTarget.value)}
                            placeholder={'Контактное лицо'} className={s.input} />
                        <input
                            value={taskContactPhone}

                            onChange={(e) => setTaskContactPhone(formatPhoneByPattern(e.currentTarget.value))}
                            className={s.input}
                            placeholder={'+7 (___) ___-____'}
                        /> {/* TODO: add phone mask*/}


                        <div className={s.title_basic_info}>Исполнитель</div>
                        <SimpleTreeSelect className={s.select}
                            value={workersTreeState.map((element) => executors.find(ele => ele.value === element).name)}
                            size={'xs'}
                            width={isMobile ? '100%' : 679}
                            showSearch
                            loading={executorsLoading}
                            onChange={value => {setWorkersTreeState(value)}}
                            //onSearch={(value) => debouncedExecutorsSearch(value)}
                            items={executors || []}
                            multiple
                            placeholder={'Северо-запад'} />
                        <div className={s.title_basic_info}>Наблюдатели</div>
                        <SimpleTreeSelect className={s.select}
                            value={observersTreeState.map((element) => workersWithGroups.find(ele => ele.value === element).name)}
                            size={'xs'}
                            width={isMobile ? '100%' : 679}
                            showSearch
                            onChange={setObserversTreeState}
                            items={workersWithGroups || []}
                            multiple
                            placeholder={'Иванов И.И'} />

                        <div className={s.title_basic_info}>Срок</div>
                        <DatePickerComponent
                            width={isMobile ? '100%' : 679}
                            value={date}
                            onChange={(value: Date | undefined) => setDate(value)}
                        />

                        <div className={s.title_basic_info}>Повторная заявка</div>
                        <SimpleSelect
                            value={secondaryApplication}
                            className={s.select}
                            size={'xs'}
                            showSearch
                            items={periods}
                            width={isMobile ? '100%' : 679}
                            onChange={setSecondaryApplication} placeholder={'Раз в месяц'} />
                        <div className={s.title_basic_info}>Тэги</div>
                        <MultipleSelect
                            values={stateTags}
                            width={isMobile ? '100%' : 679}
                            items={tags}
                            onChange={setStateTags}
                        />
                        {/*<MultipleColorSelect
                            onChange={setStateTags}
                            width={isMobile ? '100%' : 679} tags={tags}/>*/}
                        {/*<input
                            value={extraInfo}
                            onChange={(e) => setExtraInfo(e.currentTarget.value)}
                            className={s.input} style={{marginBottom: 15}} placeholder={'#Срочно'}/>*/}
                        {/*TODO: add highlighting - https://ant.design/components/select/#header*/}
                        <div className={s.main_download_wrap}>
                            <div className={s.download_btn} onClick={(event) => handleClick(event)}>
                                Загрузить файл
                                <DownloadBtn />
                            </div>
                            {/* <div className={s.download_wrap}>
                                <div className={s.download_title}>
                                    Загрузка:
                                </div>
                                <div className={s.title_file}>
                                    {fileNames?.length > 0 && fileNames?.map(i => {
                                        return {
                                            i
                                        }
                                    })}
                                </div>
                            </div> */}
                        </div>

                        <input
                            multiple={false}
                            onChange={e => e.currentTarget.files && addImageHandler(e.currentTarget.files)}
                            className={s.input}
                            style={{ display: 'none', width: '100%' }}
                            type="file"
                            accept=".doc, .xls, .xlsx, .docx, .pdf, .ods"
                            ref={hiddenInputRef}
                        />
                        {files?.length > 0 && <div className={s.basic_info_footer}>
                            <div className={s.basic_info_footer_first_part}>
                                <div className={clsx(s.parameters_title, s.margin_right)}>
                                    Прикреплён:
                                </div>
                                {files?.map((i: any) => <div className={clsx(s.attached_file, s.parameters_title)}>
                                    {i?.name}
                                    <div className={s.basic_info_footer_second_part}>
                                        <div className={s.vertical_line}></div>
                                        <div className={s.parameters_title}>
                                            <IconButton
                                                onClick={() => deleteFileHandler(i?.id)}
                                            >
                                                <BinIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                </div>)}
                            </div>
                        </div>}

                        <DividingLine marginTop={'15px'} />
                        <div className={s.footer}>
                            <Link to={((flag === 'addTemplate') || (flag === 'editTemplate')) ? '/directory/templates' : '/applications'}>
                                <ListItem
                                    button
                                    title={'Отменить'}
                                    style={{
                                        color: '#fff',
                                        background: '#FA5252',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: 4,
                                        marginRight: 20,
                                        width: 90
                                    }}
                                >{disabledButton ? 'Выйти' : 'Отмена'}</ListItem>
                            </Link>
                            <ListItem
                                button
                                disabled={disabledButton}
                                onClick={requestHandler}
                                style={{
                                    background: '#212529',
                                    color: '#fff',
                                    width: 150,
                                    borderRadius: 4,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >{(flag === 'editApp') || (flag === 'editTemplate')
                                ? 'Сохранить'
                                : 'Создать'}</ListItem>
                        </div>
                    </div>
                </div>}
        </Fade>
    );
})

const MapStateToProps = (state: TRootState) => ({
    flag: state.directoryReducer.appOrTemplateFlag,
    applicationReduxId: state.directoryReducer.applicationReduxId
})

export const NewApplicationContainer = connect(MapStateToProps, {})(NewApplication)

