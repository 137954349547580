import {myBidsPaths} from "./myBids/paths";
import {applicationPaths} from "./applications/paths";
import {extraPagePaths} from "./directory/paths";

export const ROUTE_PATH = {
    login: '/login',
    myBids: myBidsPaths,
    favourite: '/favourite',
    archive : '/archive',
    deleted : '/deleted',
    calendar: '/calendar',
    workers: '/workers',
    history: '/history',
    myApplications: applicationPaths,
    newApplication: '/newApplication',
    authorization: '/authorization',
    applicationInfo: '/applicationInfo',
    extraPage: extraPagePaths,
    reports : '/reports'
}