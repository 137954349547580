import React, { FC, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Link, NavLink } from "react-router-dom";
import { Calendar, CheckMark, Favorites, History, MainLogo, MyApplication, Notification, Workers, Archieve, Deleted, Manual, ReportIcon } from "../../icons/icons";
import Collapse from '@material-ui/core/Collapse';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import List from "@material-ui/core/List";
import { ROUTE_PATH } from "../../routers/paths.main";
import { IModal } from "../../store/sidebar/types.data";
import { Fallback } from "../helpers/Fallback/Fallback";
import { SidebarStore } from "../../store/sidebar/sidebarMobx";
import { useDispatch, useSelector } from "react-redux";
import { setTaskFilterDates } from "../../redux/reducers/directory/actions/actions";
import { TRootState } from "../../redux/store";
import { formviewAvailabilityFunc } from "../helpers/fromviewHelpers/formviewAvailability";
import BurgerMenuIcon from '../../icons/burgerMenu'
import { HeaderPageStore } from "../../store/header/Header";
import { Notifications } from "../common/Header/Notification/Notifications";
import { Fade } from "@material-ui/core";
import { key } from "../../store/api";
import { getMyInfo } from "../../redux/reducers/auth/actions/actions";

const s = require('./style.module.scss')

interface ISideBarContentProps {
    totalCount?: string,

    [key: string]: any
}

export const Sidebar: React.FC<ISideBarContentProps> = observer(({ children }) => {

    const isMobile: boolean = (window.outerWidth <= 1024)

    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    const [openSideBar, setOpenSideBar] = React.useState<boolean>(!isMobile)
    const [showExit, setShowExit] = useState<boolean>(false)
    const [showNotification, setShowNotification] = useState<boolean>(false)
    const [openAppliation, setOpenAppliation] = React.useState(false);
    const [openAdministration, setOpenAdministration] = React.useState(false);
    const handleClickAppliation = () => {
        setOpenAppliation(!openAppliation);
    };
    const handleClickAdministration = () => {
        setOpenAdministration(!openAdministration);
    };

    const PAGE_SIZE: number = 4

    const [currentPage, setCurrentPage] = useState<number>(1)
    const { showSidebar, requestApplicationsCounts, applicationsCounts, applicationsCountsLoading } = SidebarStore
    useEffect(() => {
        /*requestApplicationsCounts()*/
    }, [])

    const dispatch = useDispatch()

    const {
        getMyNotification,
        addNotification,
        requestCheckedNotification,
        notificationsTotalCount,
        notifications,
        notificationsList
    } = HeaderPageStore

    const myFormviews = useSelector((state: TRootState) => state.authReducer.myInfo?.formviews)
    const myInfo = useSelector((state: TRootState) => state.authReducer.myInfo)

    useEffect(() => {
        //if (!localStorage.getItem(key.main)) window.location.href = '/authorization'
        dispatch(getMyInfo({}))
        /*setTimeout(() => {
            dispatch(setTaskFilterDates(null))
        }, 2000)*/
    }, [])

    return (
        <div className={s.page}>
            {applicationsCountsLoading
                ? <div className={s.sidebar} style={{
                    display: 'flex',
                    height: '100vh',
                    alignItems: 'center'
                }}>
                    <Fallback color={'white'} />
                </div>
                : <div className={s.sidebar}
                    style={!showSidebar ? {
                        display: 'none'
                    } : {}}
                >
                    <div className={s.sideBarHeader}>
                        <Link to={ROUTE_PATH.myApplications.main} className={s.main_logo_wrap}>
                            <MainLogo />
                        </Link>
                        <div className={s.authMenuBlock}>
                            {isMobile && <div className={s.notification_login}>
                                <div className={s.notification}
                                    id={'notification'}
                                    onClick={() => {
                                        /*requestCheckedNotification(() => getMyNotification(0, 1))*/
                                        setShowNotification(!showNotification)
                                    }}>
                                    <Notification />
                                    {notificationsTotalCount > 0
                                        && <div className={s.red_circle_notification}>{notificationsTotalCount}</div>}

                                </div>
                                <div
                                    onMouseLeave={() => setShowNotification(false)}
                                    style={!showNotification
                                        ? { display: 'none' }
                                        : { position: 'absolute', zIndex: 10, right: '10%', background: 'white', top: '10%' }}
                                >
                                    <Notifications />
                                </div>
                                <div onClick={() => setShowExit(!showExit)} style={{ margin: '0 10px', color: 'white' }}>
                                    {myInfo?.name}
                                </div>
                                {/* {nameLogin} */}
                                <CheckMark style={showExit ? {
                                    cursor: 'pointer',
                                    transform: 'rotateX(180deg)',
                                    transition: '1s'
                                } : { cursor: 'pointer', transition: '1s' }} onClick={() => setShowExit(!showExit)} />
                            </div>}

                            {showExit && isMobile && <Fade in timeout={500}>
                                <div className={s.exitBlock}>
                                    <Link to={ROUTE_PATH.authorization}>
                                        <div onClick={() => localStorage.clear()}>
                                            Выход
                                        </div>
                                    </Link>
                                </div>
                            </Fade>}

                            {isMobile && <ListItem button style={{ width: 32, height: 32, paddingLeft: 'unset', paddingRight: 'unset' }} onClick={() => setOpenSideBar(!openSideBar)}>
                                <BurgerMenuIcon color={'white'} />
                            </ListItem>}
                        </div>
                    </div>


                    {openSideBar && <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        className={s.root}
                        onClick={() => {
                            dispatch(setTaskFilterDates(null))
                        }}
                    >
                        {formviewAvailabilityFunc(myFormviews || [], 'my_tasks') && <div className={s.list_item} onClick={handleClickAppliation}>
                            <NavLink  className={s.notactive__sidebar} activeClassName={s.active__sidebar} to={ROUTE_PATH.myApplications.main}>
                                <div style={{ marginLeft: '10%', display: 'flex', alignItems: 'center' }}>
                                    <ListItemIcon>
                                        <MyApplication />
                                    </ListItemIcon>
                                </div>
                                <span onClick={() => isMobile && setOpenSideBar(false)} style={{ fontSize: 20 }}>Мои заявки</span>
                            </NavLink>

                            {/*openAppliation ? <ExpandLess/> : <ExpandMore/>*/}
                        </div>}
                        {formviewAvailabilityFunc(myFormviews || [], 'favorite_tasks') && <div className={s.list_item}>
                            <NavLink className={s.notactive__sidebar} activeClassName={s.active__sidebar} to={ROUTE_PATH.favourite}>
                                <div style={{ marginLeft: '10%', display: 'flex', alignItems: 'center' }}>
                                    <ListItemIcon>
                                        <Favorites />
                                    </ListItemIcon>
                                </div>
                                <span onClick={() => isMobile && setOpenSideBar(false)} style={{ fontSize: 20 }}>Избранное</span>
                            </NavLink>
                        </div>}

                        {formviewAvailabilityFunc(myFormviews || [], 'archive_tasks') && <div className={s.list_item}>
                            <NavLink className={s.notactive__sidebar} activeClassName={s.active__sidebar} to={ROUTE_PATH.archive}>
                                <div style={{ marginLeft: '10%', display: 'flex', alignItems: 'center' }}>
                                    <ListItemIcon>
                                        <Archieve />
                                    </ListItemIcon>
                                </div>
                                <span onClick={() => isMobile && setOpenSideBar(false)} style={{ fontSize: 20 }}>
                                    Архив
                                </span>
                            </NavLink>
                        </div>}

                        {formviewAvailabilityFunc(myFormviews || [], 'deleted_tasks') && <div className={s.list_item}>
                            <NavLink className={s.notactive__sidebar} activeClassName={s.active__sidebar} to={ROUTE_PATH.deleted}>
                                <div style={{ marginLeft: '10%', display: 'flex', alignItems: 'center' }}>
                                    <ListItemIcon>
                                        <Deleted />
                                    </ListItemIcon>
                                </div>
                                <span onClick={() => isMobile && setOpenSideBar(false)} style={{ fontSize: 20 }}>
                                    Удаленные
                                </span>
                            </NavLink>
                        </div>}

                        {formviewAvailabilityFunc(myFormviews || [], 'calendar') && <div className={s.list_item}>
                            <NavLink className={s.notactive__sidebar} activeClassName={s.active__sidebar} to={ROUTE_PATH.calendar}>
                                <div style={{ marginLeft: '10%', display: 'flex', alignItems: 'center' }}>
                                    <ListItemIcon>
                                        <Calendar />
                                    </ListItemIcon>
                                </div>
                                <span onClick={() => isMobile && setOpenSideBar(false)} style={{ fontSize: 20 }}>Календарь</span>
                            </NavLink>
                        </div>}
                        {formviewAvailabilityFunc(myFormviews || [], 'employees') && <div className={s.list_item}>
                            <NavLink className={s.notactive__sidebar} activeClassName={s.active__sidebar} to={ROUTE_PATH.workers}>
                                <div style={{ marginLeft: '10%', display: 'flex', alignItems: 'center' }}>
                                    <ListItemIcon>
                                        <Workers />
                                    </ListItemIcon>
                                </div>
                                <span onClick={() => isMobile && setOpenSideBar(false)} style={{ fontSize: 20 }}>Сотрудники</span>
                            </NavLink>

                        </div>}

                        {formviewAvailabilityFunc(myFormviews || [], 'history') && <div className={s.list_item}>
                            <NavLink className={s.notactive__sidebar} activeClassName={s.active__sidebar} to={ROUTE_PATH.history}>
                                <div style={{ marginLeft: '10%', display: 'flex', alignItems: 'center' }}>
                                    <ListItemIcon>
                                        <History />
                                    </ListItemIcon>
                                </div>
                                <span onClick={() => isMobile && setOpenSideBar(false)} style={{ fontSize: 20 }}>История</span>
                            </NavLink>
                        </div>}

                        {formviewAvailabilityFunc(myFormviews || [], 'directory') && <div className={s.list_item}>
                            <NavLink className={s.notactive__sidebar} activeClassName={s.active__sidebar} to={ROUTE_PATH.extraPage.main}>
                                <div style={{ marginLeft: '10%', display: 'flex', alignItems: 'center' }}>
                                    <ListItemIcon>
                                        <Manual />
                                    </ListItemIcon>
                                </div>
                                <span onClick={() => isMobile && setOpenSideBar(false)} style={{ fontSize: 20, }}>
                                    Справочники
                                </span>
                            </NavLink>
                        </div>}

                        {formviewAvailabilityFunc(myFormviews || [], 'visible_report') && <div className={s.list_item}>
                            <NavLink className={s.notactive__sidebar} activeClassName={s.active__sidebar} to={ROUTE_PATH.reports}>
                                <div style={{ marginLeft: '10%', display: 'flex', alignItems: 'center' }}>
                                    <ListItemIcon>
                                        <ReportIcon />
                                    </ListItemIcon>
                                </div>
                                <span onClick={() => isMobile && setOpenSideBar(false)} style={{ fontSize: 20 }}>Отчеты</span>
                            </NavLink>
                        </div>}

                    </List>}
                </div>}

            <div className={s.content}>
                {children}
            </div>
        </div>
    )
})